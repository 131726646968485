<template>
  <div>
    <el-dialog
      :title="title + '设备类型参数'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <!-- <div class="bandanItem">
              <el-form-item label="设备类型" prop="deviceTypeId">
                <el-select
                  v-model="formInline.deviceTypeId"
                  placeholder="请选择设备类型"
                >
                  <el-option
                    v-for="item in deviceList"
                    :key="item.code"
                    :label="item.typeName"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div> -->
          <el-form-item label="参数编码" prop="paramCode">
            <el-select
              v-model="formInline.paramCode"
              placeholder="请选择参数编码"
              style="width: 100%"
            >
              <el-option
                v-for="item in paramCodeList"
                :key="item.paramCode"
                :label="item.paramName"
                :value="item.paramCode"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="参数对应值编码" prop="valueCode">
            <el-input
              placeholder="请输入参数对应值编码"
              maxlength="20"
              v-model="formInline.valueCode"
              ref="valueCode"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <div class="banben">
            <el-form-item
              label="参数对应值描述"
              prop="valueDesc"
              style="width: 100%"
            >
              <el-input
                placeholder="请输入参数对应值描述"
                v-model="formInline.valueDesc"
                type="textarea"
                ref="valueDesc"
                style="width: 100%"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </div>
          <div class="banben">
            <el-form-item label="是否默认值" prop="defaultYn">
              <el-radio-group v-model="formInline.defaultYn">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="determine('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest } from "@/api/api.js";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      deviceList: [],
      paramCodeList: [],
      //表单
      formInline: { defaultYn: 0 },
      //表单验证
      rules: {
        paramCode: [
          {
            required: true,
            message: "请选择参数编码，不能为空",
            trigger: "blur",
          },
        ],
        valueCode: [
          {
            required: true,
            message: "请输入参数对应值编码，不能为空",
            trigger: "blur",
          },
        ],
        valueDesc: [
          {
            required: true,
            message: "请输入参数对应值描述，不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
      this.formInline.statusRemark = "";
      this.formInline.code = "";
    },
    show(item, id, val) {
      console.log(555, id);
      this.formInline = { defaultYn: 0 };
      this.formInline.deviceTypeId = id;
      this.getdeviceList();
      this.getparamCodeList();
      this.title = item;
      this.showing = true;
      if (item == "编辑") {
        this.formInline = JSON.parse(JSON.stringify(val));
        this.formInline.defaultYn = Number(this.formInline.defaultYn);
      }
    },
    getdeviceList() {
      postRequest("/selectAllSysDeviceType", {}).then((res) => {
        if (res.status == 200) {
          console.log(233, res.data);
          this.deviceList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    getparamCodeList() {
      postRequest("/selectAllSysParamType", {}).then((res) => {
        if (res.status == 200) {
          console.log(233, res.data);
          this.paramCodeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    determine(formData) {
      // 表单验证-----------
      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            postRequest("/insertSysDeviceParam", this.formInline).then(
              (res) => {
                if (res.status == 200) {
                  this.$message({
            duration: 5000,
                    message: "新增成功!",
                    type: "success",
                  });
                  this.hide();
                  this.$refs.formInline.resetFields(); //清空表单
                  this.$parent.getFounderList();
                } else {
                  this.$message({
            duration: 5000,
                    message: res.message,
                    type: "warning",
                  });
                }
              }
            );
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      postRequest("/updateSysDeviceParam", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      console.log(this.fileJson);
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      console.log("删除点击");
      console.log(this.fileJson);
      console.log(file);
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    //预览
    handlePreview(file) {
      console.log("我被点击了");
      console.log(file);
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      console.log(this.isAssetTypeAnImage(ext));
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
            duration: 5000,
              message: message,
              type: "warning",
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
</style>